<script setup lang="ts">
import {useMyRoutePaths} from "~/mylib/MyRoutePaths";

const routePaths = useMyRoutePaths()
</script>

<template>
  <div class="sidebar-wrap">
    <div class="sidebar min-vh-100">
      <ul class="sidebar-menu-list">
        <li>
          <a :href="routePaths.admin.home()">ダッシュボード</a>
        </li>
        <li>
          <a :href="routePaths.admin.journals()">旅日記</a>
        </li>
        <li>
          <a :href="routePaths.admin.areaMaps()">エリアマップ</a>
        </li>
        <li>
          <a :href="routePaths.admin.photoSets()">写真投稿</a>
        </li>
        <li>
          <a :href="routePaths.admin.posts()">記事</a>
        </li>
        <li>
          <a :href="routePaths.admin.postSeriesList()">記事シリーズ</a>
        </li>
        <li>
          <a :href="routePaths.admin.selectableTags()">選択可能タグ</a>
        </li>
        <li>
          <a :href="routePaths.admin.bucketImages()">Bucket画像</a>
        </li>
        <li>
          <a href="/admin/youTube">YouTube動画</a>
        </li>
        <li>
          <a href="/admin/facebook/auth">Facebook認証</a>
        </li>
        <li>
          <a :href="routePaths.home()" target="_blank">サイトを見る</a>
        </li>
        <li>
          <a :href="routePaths.admin.logout()">ログアウト</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-wrap {
  display: block;
  width: 200px;

  .sidebar {
    padding: 30px;
  }
  .sidebar-menu-list {
    display: block;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    li {
      margin: 8px 0;
      a {
        text-decoration: none;
      }
    }
  }
}
</style>
